import React from "react"

function Main() {
    return (
        <main className="main">
            <p className="main--header">About</p>
            <p className="main--text">I am a Full Stack Engineer who loves solving problems and building solutions. I am constantly learning in an attempt to improve myself, as well as the expertise that I can offer.</p>
            <p className="main--header">Interests</p>
            <p className="main--text">Family days at the beach, video games, movies, books, a bit too much coffee.</p>
        </main>
    )
}

export default Main