import React from "react";
import GitHub from "../Images/github.png"
import Twitter from "../Images/twitter.png"

function Footer() {
    return(
        <footer className="footer">
            <a href="https://github.com/kersev/" className="footer--background">
                <img src={GitHub} alt="github icon" className="footer--icons" />  
            </a>
            <a href="https://twitter.com/AsaKersev" className="footer--background">
                <img src={Twitter} alt="twitter icon" className="footer--icons" />  
            </a>
        </footer>
    )
}

export default Footer