import React from "react";

import Button from "./Button"


function Contact() {
    return (
        <div className="buttons">
            <Button
                type="email"
            />
            <Button
                type="linkedin"
            />  
        </div>
    )
}

export default Contact