import React from "react";

function Button(props) {
    const buttonClass = `links ${props.type}`

    function handleClick(){
        if (props.type === "email") {
            return window.location = "mailto:kevin@kersev.com"
        }

        return window.location = "https://www.linkedin.com/in/kevin-mclean-536b0033/"

    }

    return(
        <button className={buttonClass} onClick={handleClick}/>
        )
}

export default Button