import React from "react";

function Titles() {
    return (
        <titles>
            <p className="my--name">Kevin McLean</p>
            <p className="my--title">Full Stack Developer</p>
            <a href="khttps://kersev.com/" className="my--site">kersev.com</a>
        </titles>
    )
}

export default Titles