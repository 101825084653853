import React from "react"

import KersevLogo from '../Images/Kersev-Logo.png'

function Header() {
    return(
        <header>
            <
                img src={KersevLogo}
                className="header--logo" 
                alt="Kersev-Logo"
            />
        </header>
    
    )
}

export default Header