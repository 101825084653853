import React from 'react';

import Header from './Components/Header'
import Titles from './Components/Titles'
import Contact from './Components/Contact'
import Main from './Components/Main'
import Footer from './Components/Footer'

function App() {
  return (
    <container>
      <Header />
      <Titles />
      <Contact />
      <Main />
      <Footer />
    </container>
  );
}

export default App;